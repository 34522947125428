<template>
    <v-container>
      <!-- Refactor this! -->
      <v-row :justify="'start'">
        <span v-for="(button, i) in data" :key="i" class="ma-1">
          <v-btn  v-if="button.button_type[0].type === 'document_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value.file">
            <v-icon left >{{button.icon}}</v-icon>
            <span >{{button.title}}</span>
          </v-btn>
          <v-btn  v-else-if="button.button_type[0].type === 'other_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value">
            <v-icon left  >{{button.icon}}</v-icon>
            <span  >{{button.title}} </span>
          </v-btn>
          <v-btn  v-else-if="button.button_type[0].type === 'page_link'"  :aria-label="button.accessibility_text"  :to="getPageLink(button)" @click="resetCurrentPopup">
            <v-icon left >{{button.icon}}</v-icon>
           <span > {{button.title}} </span>
          </v-btn>
        </span>
      </v-row>
    </v-container>
</template>
<script>
export default {
  name: "ButtonLinkRowBlock",
  props: ["data"],
  methods: {
    getPageLink(button){
      const backendUrl = button.button_type[0].value.meta.html_url
      return new URL(backendUrl).pathname
    },
    resetCurrentPopup() {
      this.$store.dispatch("wagtail/setCurrentPopup", undefined)
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  white-space: normal;
  min-height: 36px;
  height: fit-content !important;
  max-height: fit-content !important;  
  padding: 5px 16px !important;

}
::v-deep .v-btn__content {
    width: 100%;
  }
</style>
